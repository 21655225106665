import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import SwipeableViews from 'react-swipeable-views'
import { Elements } from 'react-stripe-elements'
import Cart from './cart'
import { TextField, Fab, Button, Switch, Container } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ShopIcon from '@material-ui/icons/ShoppingCart'
import NextIcon from '@material-ui/icons/ArrowForward'
import BackIcon from '@material-ui/icons/ArrowBack'
import { getCartProducts } from '../store/selectors/products'
import CartSummary from './cart-summary'
import Payment from './payment'
import * as yup from 'yup'
import api from '../api'
import Swal from 'sweetalert2'
import SwalSuccess from './swal-success'
import { orderComplete } from '../store/actions'

const useStyles = withStyles({
  views: {
    '&>div': {
      paddingBottom: '1rem'
    }
  },
  content: {
    marginTop: '1rem'
  },
  grid: {
    padding: '0 1rem'
  }
})

// prettier-ignore
const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

const requiredString = (min = 1, max = 100) =>
  yup
    .string()
    .min(min, min === 1 ? 'required' : `must be at least ${min} characters`)
    .max(max, `must be less than ${max} characters`)
    .required('required')

const requiredState = () =>
  yup
    .string()
    .required('required')
    .oneOf(states, 'Use State Abbreviation')

const optionalString = (max = 100) => yup.string().max(max)
// translate to yup
const validation = {
  user: yup.object().shape({
    email: requiredString().email('must be a valid email')
  }),
  billing: yup.object().shape({
    billing_first_name: requiredString(),
    billing_last_name: requiredString(),
    billing_address1: requiredString(),
    billing_address2: optionalString(),
    billing_city: requiredString(),
    billing_state: requiredState(),
    billing_zip: requiredString(5, 12)
  }),
  shipping: yup.object().shape({
    shipping_first_name: requiredString(),
    shipping_last_name: requiredString(),
    shipping_address1: requiredString(),
    shipping_address2: optionalString(),
    shipping_city: requiredString(),
    shipping_state: requiredState(),
    shipping_zip: requiredString(5, 12)
  }),
  coupon: optionalString()
}
// TODO: Handle Empty Cart
class Checkout extends React.Component {
  state = {
    pageNum: 0,
    differentBillingAddress: false,
    errors: {},
    email: '',
    first_name: '',
    last_name: '',
    origin: 'rockwarrior',

    billing_first_name: '',
    billing_last_name: '',
    billing_address1: '',
    billing_address2: '',
    billing_city: '',
    billing_state: '',
    billing_zip: '',

    shipping_first_name: '',
    shipping_last_name: '',
    shipping_address1: '',
    shipping_address2: '',
    shipping_city: '',
    shipping_state: '',
    shipping_zip: '',
    coupon: '',

    subtotal_cost: null,
    tax_cost: null,
    shipping_cost: null,
    total_cost: null,
    orderNumber: null
  }
  toPage = num => () => {
    this.setState({ pageNum: num })
  }
  setText = field => event => {
    this.setState({ [field]: event.target.value })
  }
  paymentSuccessful = orderNumber => {
    this.props.orderComplete()
    this.setState({ orderNumber, pageNum: 3 })
  }

  renderTextField = (label, name, autocomplete) => (
    <TextField
      fullWidth
      label={label}
      value={this.state[name]}
      onChange={this.setText(name)}
      autoComplete={autocomplete}
      error={!!this.state.errors[name]}
      helperText={this.state.errors[name]}
    />
  )
  getOrderInfo() {
    const s = this.state

    const orderInfo = {
      email: s.email,
      shipping_first_name: s.shipping_first_name,
      shipping_last_name: s.shipping_last_name,
      shipping_address1: s.shipping_address1,
      shipping_address2: s.shipping_address2,
      shipping_city: s.shipping_city,
      shipping_state: s.shipping_state,
      shipping_zip: s.shipping_zip
    }

    if (s.differentBillingAddress) {
      Object.assign(orderInfo, {
        billing_first_name: s.billing_first_name,
        billing_last_name: s.billing_last_name,
        billing_address1: s.billing_address1,
        billing_address2: s.billing_address2,
        billing_city: s.billing_city,
        billing_state: s.billing_state,
        billing_zip: s.billing_zip
      })
    } else {
      Object.assign(orderInfo, {
        billing_first_name: s.shipping_first_name,
        billing_last_name: s.shipping_last_name,
        billing_address1: s.shipping_address1,
        billing_address2: s.shipping_address2,
        billing_city: s.shipping_city,
        billing_state: s.shipping_state,
        billing_zip: s.shipping_zip
      })
    }
    Object.assign(orderInfo, {
      first_name: orderInfo.billing_first_name,
      last_name: orderInfo.billing_last_name,
      coupon: '',
      subtotal_cost: s.subtotal_cost,
      tax_cost: s.tax_cost,
      shipping_cost: s.shipping_cost,
      total_cost: s.total_cost,
      origin: s.origin
    })

    return orderInfo
  }

  toCheckout = async () => {
    const prevErrors = this.state.errors

    const userErrors = await this.validate('user')
    const shippingErrors = await this.validate('shipping')
    const billingErrors = this.state.differentBillingAddress ? await this.validate('billing') : {}

    const errors = { ...userErrors, ...shippingErrors, ...billingErrors }
    console.log(errors)
    if (Object.keys(errors).length > 0) {
      this.setState({ errors })
    } else {
      this.setState({ errors: {} })
      setTimeout(() => this.setState({ pageNum: 2 }), Object.keys(prevErrors).length > 0 ? 250 : 0)
      try {
        this.setState({
          subtotal_cost: null,
          tax_cost: null,
          shipping_cost: null,
          total_cost: null
        })

        const totals = await api.orders.build({
          items: this.props.cartProducts.map(item => ({
            ...item,
            qty: item.quantity
          })),
          coupon: '',
          billing_state: this.state.billing_state || this.state.shipping_state
        })

        this.setState({
          subtotal_cost: totals.data.subTotal,
          tax_cost: totals.data.tax,
          shipping_cost: totals.data.shipping,
          total_cost: totals.data.total
        })
      } catch (error) {
        Swal.fire('An Error Occurred', 'Please Try Again', 'error')
      }
    }
  }

  validate = async section => {
    let errors = {}
    try {
      await validation[section].validate(this.state, { abortEarly: false })
    } catch (error) {
      errors = error.inner.reduce((a, b) => ({ ...a, [b.path]: b.errors[0] }), {})
    }
    return errors
  }

  render() {
    const { classes } = this.props
    return (
      <SwipeableViews index={this.state.pageNum} className={classes.views}>
        <div>
          <Cart />
          <div className="flex-wrapper" style={{ padding: '1rem' }}>
            <div className="flex-1"></div>
            <Fab variant="extended" color="secondary" onClick={this.toPage(1)} disabled={this.props.cartProducts.length < 1}>
              <ShopIcon className="space-r" />
              Checkout
            </Fab>
          </div>
        </div>
        <div>
          <div className={classes.content}>{this.renderShippingInfo()}</div>
          <div className="flex-wrapper" style={{ padding: '1rem' }}>
            <Button onClick={this.toPage(0)}>
              <BackIcon className="space-r" />
              Back
            </Button>
            <div className="flex-1"></div>
            <Button onClick={this.toCheckout}>
              <NextIcon className="space-r" />
              Next
            </Button>
          </div>
        </div>
        <div>
          <div className={classes.content}>{this.renderPaymentInfo()}</div>
        </div>
        <div>
          <div className={classes.content}>{this.renderConfirmation()}</div>
        </div>
      </SwipeableViews>
    )
  }

  renderShippingInfo() {
    return (
      <div>
        <div className={this.props.classes.grid}>
          <Typography variant="h5">Shipping Info</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {this.renderTextField('Email', 'email', 'email')}
            </Grid>
            <Grid item xs={6}>
              {this.renderTextField('First Name', 'shipping_first_name', 'shipping given-name')}
            </Grid>
            <Grid item xs={6}>
              {this.renderTextField('Last Name', 'shipping_last_name', 'shipping family-name')}
            </Grid>
            <Grid item xs={12}>
              {this.renderTextField('Address 1', 'shipping_address1', 'shipping address-line1')}
            </Grid>
            <Grid item xs={12}>
              {this.renderTextField('Address 2', 'shipping_address2', 'shipping address-line2')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {this.renderTextField('City', 'shipping_city', 'shipping address-level2')}
            </Grid>
            <Grid item xs={12} sm={3}>
              {this.renderTextField('State', 'shipping_state', 'shipping address-level1')}
            </Grid>
            <Grid item xs={12} sm={3}>
              {this.renderTextField('Postal Code', 'shipping_zip', 'shipping postal-code')}
            </Grid>
          </Grid>
        </div>
        <div className={this.props.classes.grid}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" component="span">
                Different Billing Address
              </Typography>
              <Switch onChange={e => this.setState({ differentBillingAddress: e.target.checked })} />
            </Grid>
          </Grid>
        </div>
        {this.state.differentBillingAddress && (
          <div className={this.props.classes.grid} style={{ paddingTop: '1rem' }}>
            <Typography variant="h5">Billing Info</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {this.renderTextField('First Name', 'billing_first_name', 'billing given-name')}
              </Grid>
              <Grid item xs={6}>
                {this.renderTextField('Last Name', 'billing_last_name', 'billing family-name')}
              </Grid>
              <Grid item xs={12}>
                {this.renderTextField('Address 1', 'billing_address1', 'billing address-line1')}
              </Grid>
              <Grid item xs={12}>
                {this.renderTextField('Address 2', 'billing_address2', 'billing address-line2')}
              </Grid>
              <Grid item xs={12} sm={6}>
                {this.renderTextField('City', 'billing_city', 'billing address-level2')}
              </Grid>
              <Grid item xs={12} sm={3}>
                {this.renderTextField('State', 'billing_state', 'billing address-level1')}
              </Grid>
              <Grid item xs={12} sm={3}>
                {this.renderTextField('Postal Code', 'billing_zip', 'billing postal-code')}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    )
  }

  renderPaymentInfo() {
    const s = this.getOrderInfo()
    return (
      <div>
        <div className={this.props.classes.grid}>
          <Typography variant="h5">Review Order</Typography>
        </div>
        <div style={{ height: '1rem' }} />
        <div className={this.props.classes.grid}>
          <Grid container>
            <Grid item xs={6} style={{ borderLeft: '1px solid #ccc', padding: '0 1rem' }}>
              <Typography variant="h5">Ship To</Typography>
              <Typography variant="body1">
                {s.shipping_first_name} {s.shipping_last_name}
              </Typography>
              <Typography variant="body1">{s.shipping_address1}</Typography>
              <Typography variant="body1">{s.shipping_address2}</Typography>
              <Typography variant="body1">
                {s.shipping_city}, {s.shipping_state} {s.shipping_zip}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ borderLeft: '1px solid #ccc', padding: '0 1rem' }}>
              <Typography variant="h5">Bill To</Typography>
              <Typography variant="body1">
                {s.billing_first_name} {s.billing_last_name}
              </Typography>
              <Typography variant="body1">{s.billing_address1}</Typography>
              <Typography variant="body1">{s.billing_address2}</Typography>
              <Typography variant="body1">
                {s.billing_city}, {s.billing_state} {s.billing_zip}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ height: '1rem' }} />
        <CartSummary orderInfo={s} />

        <div style={{ height: '1rem' }} />
        <div className={this.props.classes.grid}>
          <Elements>
            <Payment orderInfo={s} itemInfo={this.props.cartProducts} onBack={this.toPage(1)} onSuccess={this.paymentSuccessful} />
          </Elements>
        </div>
      </div>
    )
  }

  renderConfirmation() {
    return (
      <Container style={{ marginTop: '4rem', textAlign: 'center' }}>
        <SwalSuccess style={{ margin: '0 auto', display: 'inline-block' }} />
        <Typography variant="h4" style={{ marginBottom: '1rem' }}>
          Thank you for your order!
        </Typography>
        <Typography variant="h5" style={{ marginBottom: '1rem' }}>
          Your order number is {this.state.orderNumber}
        </Typography>
        <Typography variant="body1">You will receive an email shortly.</Typography>
      </Container>
    )
  }
}

export default connect(
  state => ({
    cartProducts: getCartProducts(state)
  }),
  { orderComplete }
)(useStyles(Checkout))
