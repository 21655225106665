import React from 'react'
import { connect } from 'react-redux'
import { Container, Grid, Typography } from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/styles'
// import { Link } from 'react-router-dom'
// @ts-ignore
import { Parallax } from 'react-parallax'
import { getTrainingProducts } from '../store/selectors/products'
import ItemCard from '../components/item-card'

const useStyles = withStyles({
  title: {
    border: 0,
    padding: 0,
    borderTop: '1px solid #ccc',
    '& legend': {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  }
})

class Training extends React.Component {
  // TODO: make a totally religious workout video playlist on new youtube channel: totallyreligiousworkout
  makeYoutubeVideo = () => (
    <div className="video-wrapper">
      <iframe
        title="video"
        width="100%"
        height="100%"
        frameBorder="0"
        src="https://www.youtube.com/embed/videoseries?list=PLoz9kRrcjU_oTiAMthXYKSSktjaH-p9ox&modestbranding=1&rel=0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )

  isWidth = (...sizes) => sizes.includes(this.props.width)

  render() {
    const { classes } = this.props
    return (
      <div>
        <Parallax bgImage="/img/dumbbells-storm.jpg" strength={400} bgImageStyle={{ top: '7rem' }}>
          <div
            style={{
              backgroundColor: 'rgba(0,0,0,0.57)',
              color: 'white',
              textShadow: '2px 2px 4px rgba(0,0,0,0.6)'
            }}
          >
            <div style={{ paddingTop: '5rem' }}></div>
            <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
              <fieldset className={classes.title}>
                <legend className="center">
                  <Typography variant="h2" className="stencil" style={{ marginBottom: '0' }}>
                    Training
                  </Typography>
                </legend>
              </fieldset>
              <Typography variant="h5" style={{ paddingBottom: '4rem' }}>
                For Spirit and Body
              </Typography>
              <Typography variant={this.isWidth('xs') ? 'h5' : 'h4'} className="serif img-text" style={{ lineHeight: 1.6 }}>
                This... is where you can
                <br /> glean the habits and revelations
                <br /> of how to use God's Wisdom and Light...
                <br />
                <br /> to have a healthy body, mind, and soul...
                <br />
                <br /> <big> It's through His Spirit!</big>
                <br /> <big> Come into the Light.</big>
              </Typography>
              <div style={{ height: '2rem' }} />
            </Container>
          </div>
        </Parallax>

        <Container style={{ paddingBottom: '1rem' }}>
          <Grid container direction="row" alignContent="stretch" alignItems="stretch" spacing={2} className="space-v">
            <Grid item xs={12}>
              <img alt="Quote: Lightning In A Bottle" src="/img/quotes/2017-03-14 - Lightning In A Bottle.jpg" style={{ maxWidth: '100%' }} />
            </Grid>
          </Grid>

          {/* 
           <Typography variant="h3" className="space-v left">
            Training Videos
          </Typography>
          <Grid container direction="row" alignContent="stretch" alignItems="stretch" spacing={2} className="space-v">
            <Grid item xs={12}>
              {this.makeYoutubeVideo()}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className="serif space-v right">
                <a href="https://www.youtube.com/user/doubleeaglesministry" style={{ textDecoration: 'underline' }}>
                  subscribe
                </a>
              </Typography>
            </Grid>
          </Grid> */}
        </Container>

        <div style={{ backgroundColor: '#e2e2e2', paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Container>
            <Typography variant="h4" className="stencil">
              Totally Religious Lineup
            </Typography>
            <Grid container direction="row" justify="center" alignItems="center" spacing={2} style={{ marginTop: '2rem', backgroundColor: 'transparent' }}>
              {this.props.products.map(item => (
                <Grid item key={item.id} md={4} sm={6} xs={12}>
                  <ItemCard item={item} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}
export default connect(
  // @ts-ignore
  state => ({ products: getTrainingProducts(state) }),
  {}
)(withWidth()(useStyles(Training)))
