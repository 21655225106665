import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles({
  root: {
    flex: 0,
    paddingTop: '3rem',
    paddingBottom: '3rem'
  }
})

function SectionHeader({ color = 'black', children }) {
  const classes = useStyles({})
  return (
    <div className={classes.root} style={{ color: color }}>
      <Container>
        <Typography variant="h2" className="stencil">
          {children}
        </Typography>
      </Container>
    </div>
  )
}

SectionHeader.propTypes = {
  bgColor: PropTypes.string
}

export default SectionHeader
