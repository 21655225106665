import React from 'react'
import { connect } from 'react-redux'
import { Container, Grid, Typography } from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/styles'
// import { Link } from 'react-router-dom'
// @ts-ignore
import { Parallax } from 'react-parallax'

const useStyles = withStyles({
  title: {
    border: 0,
    padding: 0,
    borderTop: '1px solid #ccc',
    '& legend': {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  }
})

// TEEHHtQWGJo&list=PLoz9kRrcjU_oTiAMthXYKSSktjaH-p9ox
// Latest Video from channel: src="https://www.youtube.com/embed?listType=user_uploads&list=doubleeaglesministry&modestbranding=1&rel=0"
// Latest Video from playlist: src="https://www.youtube.com/embed/videoseries?list=TEEHHtQWGJo&list=PLoz9kRrcjU_oTiAMthXYKSSktjaH-p9ox&modestbranding=1&rel=0&max-results=1"
class Lifestyle extends React.Component {
  makeYoutubeVideo = () => (
    <div className="video-wrapper">
      <iframe
        title="video"
        width="100%"
        height="100%"
        frameBorder="0"
        src="https://www.youtube.com/embed/videoseries?list=PLoz9kRrcjU_oTiAMthXYKSSktjaH-p9ox&modestbranding=1&rel=0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )

  isWidth = (...sizes) => sizes.includes(this.props.width)

  render() {
    const { classes } = this.props
    return (
      <div>
        <Parallax bgImage="/img/lifestyle.jpg" strength={500}>
          <div style={{ backgroundColor: 'rgba(0,0,0,0.57)' }}>
            <Container style={{ paddingTop: '7rem', paddingBottom: '2rem', color: 'white', textShadow: '2px 2px 4px rgba(0,0,0,0.6)' }}>
              <fieldset className={classes.title}>
                <legend className="center">
                  <Typography variant="h2" className="stencil" style={{ marginBottom: '0' }}>
                    Lifestyle
                  </Typography>
                </legend>
              </fieldset>
              <Typography variant="h5">Resources to help you stay focused, regenerated, and live a holy lifestyle</Typography>
              <div style={{ height: '2rem' }} />
              <Typography variant={this.isWidth('xs') ? 'h5' : 'h4'} className="serif space-v" style={{ lineHeight: '1.6' }}>
                Here you'll find keys and ways <br />
                to unlock your spirit and body...
                <br />
                <br /> to live a victorious,
                <br /> healthy life in the faith of Jesus.
                <br />
                <br /> Faith... Comes by Hearing...
              </Typography>
              <div style={{ height: '3rem' }} />
            </Container>
          </div>
        </Parallax>

        <Container style={{ borderBottom: '1px solid #ccc', paddingBottom: '1rem' }}>
          <Typography variant="h3" className="space-v left">
            Podcasts
          </Typography>
          <Grid container direction="row" alignContent="stretch" alignItems="stretch" spacing={2}>
            <Grid item xs={12}>
              <iframe
                title="podcasts"
                width="100%"
                height="450"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/210295023&color=%23599cb4&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
              ></iframe>
            </Grid>
          </Grid>
          <Typography variant="h5" className="serif space-v right">
            <a href="https://soundcloud.com/double-eagles-ministry" style={{ textDecoration: 'underline' }}>
              view more on soundcloud
            </a>
          </Typography>
          <hr />
          <div style={{ height: '2rem' }} />
          <Typography variant="h3" className="space-v left">
            Teachings
          </Typography>

          <Grid container direction="row" alignContent="stretch" alignItems="stretch" spacing={2} className="space-v">
            <Grid item xs={12}>
              {this.makeYoutubeVideo()}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className="serif space-v right">
                <a href="https://www.youtube.com/user/doubleeaglesministry" style={{ textDecoration: 'underline' }}>
                  subscribe
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}
export default connect(
  // @ts-ignore
  // state => ({ width: state.site.width }),
  state => ({}),
  {}
)(withWidth()(useStyles(Lifestyle)))
