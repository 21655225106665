export default {
  SET_ALL_PRODUCTS: 'SET_ALL_PRODUCTS',
  SET_CATEGORY: 'SET_CATEGORY',
  ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
  REMOVE_ITEM_FROM_CART: 'REMOVE_ITEM_FROM_CART',
  UPDATE_ITEM_IN_CART: 'UPDATE_ITEM_IN_CART',
  UPDATE_CART: 'UPDATE_CART',
  GET_ORDERS: 'GET_ORDERS', // Future State, when we have users logging in
  COMPLETE_ORDER: 'COMPLETE_ORDER',
  CLEAR_CART: 'CLEAR_CART',
  SET_ORIENTATION: 'SET_ORIENTATION'
}
