import React from 'react'
import { withStyles } from '@material-ui/styles'
// @ts-ignore
import { Parallax } from 'react-parallax'
import withWidth from '@material-ui/core/withWidth'
import { Container, Typography } from '@material-ui/core'

const useStyles = withStyles({
  title: {
    border: 0,
    padding: 0,
    borderTop: '1px solid #ccc',
    '& legend': {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  logo: {
    height: '30vh'
  }
})

class About extends React.Component {
  state = {}

  isWidth = (...sizes) => sizes.includes(this.props.width)

  render() {
    const { classes } = this.props
    return (
      <div style={{ paddingBottom: '1rem' }}>
        <Parallax bgImage="/img/high-desert.jpg" strength={500}>
          <div style={{ backgroundColor: 'rgba(0,0,0,0.57)' }}>
            <Container style={{ paddingTop: '7rem', paddingBottom: '2rem', color: 'white', textShadow: '2px 2px 4px rgba(0,0,0,0.6)' }}>
              <fieldset className={classes.title}>
                <legend className="center">
                  <Typography variant="h2" className="stencil" style={{ marginBottom: '0' }}>
                    About
                  </Typography>
                </legend>
              </fieldset>
              <Typography variant="h5">Rock Warrior Club</Typography>
              <div style={{ height: '2rem' }} />
              <img src={`/img/rwc-logo.png`} alt="Rock Warrior Club" className={this.props.classes.logo} />
              <Typography variant={this.isWidth('xs') ? 'h5' : 'h4'} className="serif space-v" style={{ lineHeight: '1.6' }}>
                Rock Warrior Club is a platform for the anointing of God, through training, lifestyle, media, and apparel.
                <br />
                <br />
                Our exclusive content expresses divine revelations...
                <br /> to live a healthy and holy lifestyle in Jesus Christ.
                <br />
                <br />
                <big>Rock Warrior Club.</big>
                <hr />
                &ldquo; Wear Boldly. Shine Brightly. &rdquo;
              </Typography>
              <Typography variant={this.isWidth('xs') ? 'h4' : 'h3'} className="serif space-v" style={{ lineHeight: '1.6' }}></Typography>

              <div style={{ height: '3rem' }} />
            </Container>
          </div>
        </Parallax>
      </div>
    )
  }
}

export default withWidth()(useStyles(About))
