import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'

const useStyles = withStyles({
  root: {
    height: '10rem',
    backgroundColor: 'rgba(0,0,0,0.57)',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    transition: 'background-color 0.5s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.68)'
    },
    '&:hover .scale-on-hover': {
      transform: 'scale(1.1)'
    }
  },
  bg: {
    zIndex: -1,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 0.5s'
  },
  content: {
    zIndex: 1,

    color: 'white'
  }
})

function Topic({ imgSrc, link, children, classes, history, beforeRoute = () => {} }) {
  return (
    <div
      className={classes.root}
      onClick={() => {
        beforeRoute()
        history.push(link)
      }}
    >
      <div className={classes.bg + ' scale-on-hover'} style={{ backgroundImage: `url(${imgSrc})` }}></div>
      <div className={classes.content + ' stencil'}>{children}</div>
    </div>
  )
}

Topic.propTypes = {
  imgSrc: PropTypes.string,
  link: PropTypes.string,
  history: PropTypes.any
}

// @ts-ignore
export default useStyles(withRouter(Topic))
