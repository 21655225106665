import { combineReducers } from 'redux'
import products from './products'
import shoppingCart from './shopping-cart'
import site from './site'

export default combineReducers({
  products,
  shoppingCart,
  site
})
