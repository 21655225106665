import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'
// import Fade from 'react-reveal/Fade'
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import ItemCard from '../components/item-card'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { getFirstOrderProducts } from '../store/selectors/products'
import { setCategory } from '../store/actions'

const useStyles = withStyles({
  title: {
    border: 0,
    padding: 0,
    borderTop: '1px solid #ccc',
    '& legend': {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  logo: {
    height: '30vh'
  },
  select: {
    backgroundColor: 'white',
    marginTop: '2rem',
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,0.23)',
      borderWidth: '1px'
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
      outline: '3px green !important'
    }
  },
  divider: {
    marginLeft: '1rem',
    marginRight: '1rem',
    display: 'inline-block',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '-3rem',
      bottom: '-3rem',
      width: '1px',
      display: 'block',
      borderRight: '1px solid #ccc'
    }
  }
})

const categories = ['All', 'Health', "Men's Clothing", "Women's Clothing", 'Entertainment']

class Shop extends React.Component {
  handleCategory = event => {
    this.props.setCategory(event.target.value)
  }
  makeCategorySelect = () => {
    return (
      <Select
        className={this.props.classes.select}
        variant="outlined"
        value={this.props.category}
        onChange={this.handleCategory}
        renderValue={value => (
          <div>
            <strong>Category</strong>
            <div className={this.props.classes.divider}></div> {value}
          </div>
        )}
        name="category"
        style={{ width: '100%', textAlign: 'left', flex: 1 }}
      >
        {categories.map(category => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    )
  }
  render() {
    const { classes } = this.props
    return (
      <div style={{ backgroundColor: '#f2f2f2', minHeight: '100vh', paddingBottom: '1rem' }}>
        <div style={{ paddingTop: '7rem' }}>
          <Container style={{ paddingBottom: '2rem' }}>
            <fieldset className={classes.title}>
              <legend className="center">
                <Typography variant="h2" className="stencil" style={{ marginBottom: '0' }}>
                  Rock Warrior Club
                </Typography>
              </legend>
            </fieldset>
            <Typography variant="h5" className="stencil">
              Your premiere source to living a healthy, holy and victorious life In Jesus
            </Typography>
          </Container>
          <Container>
            {/* <Typography variant="h4" className="stencil">
              Rock Warrior Club
            </Typography>
            <hr />
            <Typography variant="h5" className="stencil">
              Your premiere source To living a healthy, holy and victorious life In Jesus
            </Typography> */}
            {this.makeCategorySelect()}
            <Grid container direction="row" justify="center" alignItems="center" spacing={2} style={{ marginTop: '2rem', backgroundColor: 'transparent' }}>
              {this.props.products.map(item => (
                <Grid item key={item.id} md={4} sm={6} xs={12}>
                  <ItemCard item={item} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </div>
    )
  }
}

export default connect(
  // @ts-ignore
  state => ({ orientation: state.site.orientation, products: getFirstOrderProducts(state), category: state.site.category }),
  { setCategory }
)(useStyles(Shop))
