import React from 'react'
import './App.css'
import Nav from './components/nav'

import { connect } from 'react-redux'
import { setOrientation } from './store/actions'
import { Switch, Route } from 'react-router-dom'
import ScrollMemory from 'react-router-scroll-memory'

import Home from './pages/home'
import Shop from './pages/shop'
import Product from './pages/product'
import Lifestyle from './pages/lifestyle'
import Training from './pages/training'
import Affiliates from './pages/affiliates'
import About from './pages/about'
import { Typography } from '@material-ui/core'

class App extends React.Component {
  componentDidMount() {
    window.addEventListener('orientationchange', this.props.setOrientation)
    window.addEventListener('resize', this.props.setOrientation)
  }

  render() {
    return (
      <div className="App">
        <ScrollMemory />
        <Nav />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/affiliates" component={Affiliates} />
          <Route exact path="/lifestyle" component={Lifestyle} />
          <Route exact path="/training" component={Training} />
          <Route exact path="/shop" component={Shop} />
          <Route path="/shop/item/:id" component={Product} />
        </Switch>
        <footer>
          <div style={{ padding: '2rem 0', textAlign: 'center', backgroundColor: '#333', color: 'white' }}>
            <img src={`/img/rwc-logo.png`} alt="Rock Warrior Club" style={{ height: '8rem' }} />
            <Typography variant="h5">&copy; {new Date().getFullYear()} Rock Warrior Club</Typography>
            <Typography variant="body1">All Rights Reserved</Typography>
          </div>
        </footer>
      </div>
    )
  }
}

export default connect(
  // @ts-ignore
  state => ({ orientation: state.site.orientation }),
  { setOrientation }
)(App)
