import api from '../api'
import types from './types'
import Swal from 'sweetalert2'

const findById = (array, id) => array.find(x => x.id === id)

export const getAllProducts = () => async dispatch => {
  const products = await api.products.getAll()
  dispatch({
    type: types.SET_ALL_PRODUCTS,
    payload: products.data
  })
}

export const addToCart = (itemId, options = {}, routeId, quantity = 1) => (dispatch, getState) => {
  const item = findById(getState().products, itemId)
  const currentInStock = item.on_hand_qty - item.reserved_qty
  if (currentInStock >= quantity) {
    dispatch({
      type: types.ADD_ITEM_TO_CART,
      payload: {
        itemId: item.id,
        options,
        routeId,
        quantity
      }
    })
  } else {
    Swal.fire('Sorry!', `You can order up to ${currentInStock} `, 'warning')
  }
}

export const updateItemInCart = (itemId, options = {}, quantity = 1) => (dispatch, getState) => {
  const item = findById(getState().products, itemId)
  const currentInStock = item.on_hand_qty - item.reserved_qty
  if (currentInStock >= quantity) {
    dispatch({
      type: types.UPDATE_ITEM_IN_CART,
      payload: {
        itemId,
        options,
        quantity
      }
    })
  } else {
    Swal.fire('Sorry!', `Due to demand, you can only order up to ${currentInStock} of: <br/><em>${item.title}</em>`, 'warning')
  }
}

export const removeItemFromCart = (itemId, options = {}) => (dispatch, getState) => {
  dispatch({
    type: types.REMOVE_ITEM_FROM_CART,
    payload: {
      itemId,
      options
    }
  })
}

export const orderComplete = () => dispatch => {
  dispatch({
    type: types.COMPLETE_ORDER
  })
}

export const setOrientation = () => dispatch => {
  dispatch({
    type: types.SET_ORIENTATION
  })
}

export const setCategory = category => dispatch => {
  dispatch({
    type: types.SET_CATEGORY,
    payload: {
      category
    }
  })
}
