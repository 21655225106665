import React from 'react'
import PropTypes from 'prop-types'
// @ts-ignore
import { Parallax } from 'react-parallax'
import { withStyles } from '@material-ui/styles'

const useStyles = withStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    '& *': {
      textShadow: '2px 2px 4px rgba(0,0,0,0.8)',
      color: 'white'
    }
  }
})

function Hero({ imageSrc, strength = 500, children, classes }) {
  return (
    <Parallax bgImage={imageSrc} strength={strength}>
      <div className={classes.root}>{children}</div>
    </Parallax>
  )
}

Hero.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  strength: PropTypes.number
}

export default useStyles(Hero)
