import types from '../types'
import isEqual from 'lodash/isEqual'

const matchPayload = (item, payload) => item.itemId === payload.itemId && isEqual(item.options, payload.options)

const shoppingCart = (state = [], action) => {
  switch (action.type) {
    case types.ADD_ITEM_TO_CART:
      // if item exists, increase quantity. Otherwise, add new item.
      const currentItem = state.find(item => matchPayload(item, action.payload))
      if (currentItem) {
        return state.map(item => (item === currentItem ? { ...item, quantity: item.quantity + action.payload.quantity } : item))
      } else {
        return [
          ...state,
          {
            ...action.payload
          }
        ]
      }

    case types.REMOVE_ITEM_FROM_CART:
      return state.filter(item => !matchPayload(item, action.payload))
    case types.UPDATE_ITEM_IN_CART:
      return state.map(item => (matchPayload(item, action.payload) ? { ...item, ...action.payload } : item))
    case types.CLEAR_CART:
    case types.COMPLETE_ORDER:
      return []
    default:
      return state
  }
}

export default shoppingCart
