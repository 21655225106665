import { createSelector } from 'reselect'

const productsSelector = state => state.products || []
const cartSelector = state => state.shoppingCart || []
const categorySelector = state => state.site.category
const itemSelector = (state, props) => props.match.params.id

const byTitle = (a, b) => a.title.localeCompare(b.title)

function join(left, right, on, yielding) {
  return left.map(x => right.filter(y => on(x, y)).map(y => yielding(x, y))).reduce((a, b) => a.concat(b), [])
}

export const getFirstOrderProducts = createSelector(
  [productsSelector, categorySelector],
  (items, category) => items.filter(item => !item.is_variant && (category === 'All' || item.categories.includes(category))).sort(byTitle)
)

export const getTrainingProducts = createSelector(
  [productsSelector],
  items => items.filter(item => !item.is_variant && item.categories.includes('TR')).sort(byTitle)
)

export const getVariantProducts = createSelector(
  [productsSelector],
  items => items.filter(item => item.is_variant)
)

export const getProduct = createSelector(
  [getFirstOrderProducts, itemSelector],
  (items, id) => items.find(item => item.id === id)
)

export const getProductVariants = createSelector(
  [getProduct, getVariantProducts],
  (product, variants) => (product && product.variants ? product.variants.map(v => variants.find(i => i.id === v.id)).filter(x => x) : [])
)

export const getCartProducts = createSelector(
  [cartSelector, productsSelector],
  (cart, products) => {
    return join(cart, products, (c, p) => c.itemId === p.id, (c, p) => ({ ...p, ...c }))
  }
)
