import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import FullscreenIcon from '@material-ui/icons/Add'
import Truncate from 'react-truncate'
import Markdown from 'markdown-to-jsx'

const useStyles = withStyles(theme => ({
  root: {},
  content: {
    height: '10rem',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    marginTop: 0,
    flex: 0
  },
  price: {
    margin: 0,
    padding: 0,
    marginTop: '-0.5rem',
    marginBottom: '0.5rem'
  },
  description: {
    ...theme.typography.body2,
    flex: 1,
    overflow: 'hidden',
    height: '3.75rem',
    lineHeight: '1.25rem',
    paddingBottom: '0.5rem',
    textAlign: 'justify'
  },
  fab: {
    position: 'absolute',
    top: '0',
    marginTop: '-28px',
    right: '1rem'
  }
}))

function Space() {
  return <span>&nbsp;&nbsp;</span>
}

function ItemCard({ item, children, classes, history }) {
  const gotoProduct = () => {
    setTimeout(() => history.push(`/shop/item/${item.id}`), 250)
  }

  // TEMPORARY business rules
  const img = item.imgs[0] || ''
  const [objectFit, padding] = img.includes('.jpg') && !item.id.includes('comic') ? ['cover', 0] : ['contain', '0.5rem']

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={gotoProduct} style={{ backgroundColor: '#d4d4d4' }}>
        <CardMedia style={{ objectFit, padding }} component="img" alt={item.title} height={400} image={`/img/products/${item.imgs[0]}`} title={item.title} />
      </CardActionArea>
      <CardContent className={classes.content}>
        <Fab className={classes.fab} color="secondary" onClick={gotoProduct}>
          <FullscreenIcon />
        </Fab>
        <Typography gutterBottom variant="h6" className={classes.title}>
          {item.title}
        </Typography>
        <Typography variant="body2" className={classes.price}>
          $ {item.price}
        </Typography>
        <div className={classes.description}>
          <Truncate lines={3}>
            <Markdown options={{ forceInline: true, overrides: { br: { component: Space } } }}>{item.description}</Markdown>
          </Truncate>
        </div>
      </CardContent>
    </Card>
  )
}

ItemCard.propTypes = {
  item: PropTypes.any
}

// @ts-ignore
export default useStyles(withRouter(ItemCard))
