import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import ButtonBase from '@material-ui/core/ButtonBase'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@material-ui/icons/Close'
import { withRouter, Link } from 'react-router-dom'
import { getCartProducts } from '../store/selectors/products'
import { updateItemInCart, removeItemFromCart } from '../store/actions'
import Chip from '@material-ui/core/Chip'

const useStyles = withStyles(theme => ({
  root: {
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'row',
    transition: 'background-color 1s',
    height: '5rem'
  },
  appBarContent: {
    height: '5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    userSelect: 'none'
  },
  rootAtTop: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    boxShadow: 'none'
  },
  logo: {
    height: '5rem',
    display: 'inline'
  },
  rightWrapper: {
    flex: 0,
    paddingRight: '1rem',
    paddingLeft: '1rem'
  },
  buttonBase: {
    width: '4rem',
    height: '4rem'
  },
  appIcon: {
    width: '2.2rem',
    height: '2.2rem',
    color: 'white'
  },
  logoWrapper: {
    flex: 0,
    paddingLeft: '0rem',
    paddingRight: '1rem'
  },
  flex: { flex: 1 },
  badge: {
    margin: 0
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  nested: {
    marginLeft: theme.spacing(4)
  },
  textField: {
    width: '10rem',
    textAlign: 'right'
  },
  chip: {
    margin: '0 0.5rem'
  }
}))

const qtyList = [...Array(100).keys()]
qtyList.shift()

const toDollar = num => (Math.round(num * 100) / 100).toFixed(2)
const toChips = opts => (
  <div style={{ marginTop: '0.25rem' }}>
    {Object.entries(opts).map((kv, ix) => (
      <Chip key={ix} label={`${kv[0]} | ${kv[1]}`} size="small" style={{ margin: '0.125rem', textTransform: 'capitalize' }} />
    ))}
  </div>
)

class Cart extends React.Component {
  render() {
    const { classes, closeCart } = this.props
    const cartProducts = this.props.cartProducts || []
    return (
      <div>
        <Table style={{ maxWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '1rem', paddingRight: '1rem' }}></TableCell>
              <TableCell>Item</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartProducts.map((cartItem, ix) => (
              <TableRow key={ix}>
                <TableCell align="right" style={{ width: '1rem', paddingRight: '0' }}>
                  <ButtonBase aria-label="Delete Item" onClick={() => this.props.removeItemFromCart(cartItem.itemId, cartItem.options)}>
                    <DeleteIcon />
                  </ButtonBase>
                </TableCell>
                <TableCell>
                  <Link to={`/shop/item/${cartItem.routeId}`} className={classes.link} onClick={closeCart}>
                    {cartItem.title}
                  </Link>
                  {cartItem.options && toChips(cartItem.options)}
                </TableCell>
                <TableCell align="right">
                  <Select value={cartItem.quantity} onChange={e => this.props.updateItemInCart(cartItem.id, cartItem.options, e.target.value)}>
                    {qtyList.map(v => (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell align="right">$ {toDollar(cartItem.price * cartItem.quantity)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell style={{ borderBottom: 'none', width: '1rem', paddingRight: '0' }}></TableCell>
              <TableCell style={{ borderBottom: 'none' }}>SubTotal</TableCell>
              <TableCell align="right" style={{ borderBottom: 'none' }}></TableCell>
              <TableCell align="right" style={{ borderBottom: 'none' }}>
                <strong>$ {toDollar(cartProducts.reduce((a, b) => a + b.price * b.quantity, 0))}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }
}

Cart.propTypes = {
  closeCart: PropTypes.func
}

export default connect(
  state => ({
    cartProducts: getCartProducts(state)
  }),
  { updateItemInCart, removeItemFromCart }
)(useStyles(withRouter(Cart)))
