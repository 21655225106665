import types from '../types'

function getOrientation() {
  return window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal'
}
function getWidth() {
  return window.innerWidth
}

const initialState = {
  orientation: getOrientation(),
  width: getWidth(),
  category: 'All'
}

const siteSettings = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ORIENTATION:
      return {
        ...state,
        orientation: getOrientation(),
        width: getWidth()
      }
    case types.SET_CATEGORY:
      return {
        ...state,
        category: action.payload.category
      }
    default:
      return state
  }
}

export default siteSettings
