import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

const useStyles = makeStyles({
  root: {
    flex: 0,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1rem',
    textTransform: 'capitalize'
  }
})

function Toggle({ title, values, value, onChange, components, displayFn }) {
  const classes = useStyles({})
  return (
    <div className={classes.root}>
      <Typography variant="h6" style={{ flex: 0, textAlign: 'left' }}>
        <span style={{ display: 'inline-block', width: '5rem' }}>{title}:</span>
      </Typography>
      <ToggleButtonGroup value={value} exclusive onChange={onChange} aria-label="text alignment" style={{ flex: 1 }}>
        {values.map((v, ix) => (
          <ToggleButton key={v} value={v} aria-label="Extra Small">
            {components && components[ix] ? components[ix] : <Typography variant="body1">{v}</Typography>}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  )
}

Toggle.propTypes = {
  title: PropTypes.string,
  values: PropTypes.array,
  value: PropTypes.any,
  displayFn: PropTypes.func,
  onChange: PropTypes.func,
  components: PropTypes.array
}

export default Toggle
