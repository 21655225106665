import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
// @ts-ignore
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import Toggle from '../components/toggle'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import AddShoppingCartIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/ArrowBackIos'
import Button from '@material-ui/core/Button'
import { getProduct, getProductVariants } from '../store/selectors/products'
import Skeleton from '@material-ui/lab/Skeleton'
import { addToCart } from '../store/actions'
import Markdown from 'markdown-to-jsx'

const useStyles = withStyles(theme => ({
  logo: {
    height: '30vh'
  },
  markdown: {
    ...theme.typography.body1,
    textAlign: 'justify',
    lineHeight: 1.6
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 'calc(90vh - 12rem)'
  },
  productImg: {
    width: '100%',
    maxHeight: 'calc(90vh - 12rem)',
    margin: '0 2rem',
    objectFit: 'contain'
  }
}))

function Paragraph(props) {
  return (
    <Typography variant="body1" style={{ paddingBottom: '1rem', lineHeight: `1.6` }}>
      {props.children}
    </Typography>
  )
}
const sizeOrder = { XS: 0, S: 1, M: 2, L: 3, XL: 4, '2XL': 5, '3XL': 6 }
const compareSize = (size1, size2) => sizeOrder[size1.toUpperCase()] - sizeOrder[size2.toUpperCase()]
const colorFn = color => <div style={{ backgroundColor: color, width: '1rem', height: '1rem', border: '1px solid #ccc' }} />
const isInStock = (item, variant) => {
  // if there is a variant, but the system coalesced to the original item
  if (item.variants && variant === null) {
    // consider we're out of stock
    return false
  }
  if (variant) {
    const availableStock = variant.on_hand_qty - variant.reserved_qty
    if (availableStock <= 0) {
      return false
    }
  }
  if (item) {
    const availableStock = item.on_hand_qty - item.reserved_qty
    if (availableStock <= 0) {
      return false
    }
  }
  return true
}

/**
 * Decisions:
 *  1) to reduce complexity, if a variant can't be found and the item supports variants, we simply put "Out of Stock"
 *     and disable the buy button
 */

class Product extends React.Component {
  state = {
    options: {}
  }
  componentDidMount() {
    if (this.props.item) {
      this.setState({ options: this.props.item.defaults })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.item && !prevProps.item) {
      this.setState({ options: this.props.item.defaults || {} })
    }
  }

  handler = field => (event, value) => {
    this.setState({ [field]: value })
  }

  toggleHandler = field => (event, value) => {
    this.setState({ options: { ...this.state.options, [field]: value } })
  }

  getVariantId() {
    const variantItemPath = this.props.item.variants.find(variant => Object.keys(this.state.options).every(key => this.state.options[key] === variant[key]))
    return variantItemPath ? variantItemPath.id : null
  }

  getVariant() {
    if (this.props.item.variants) {
      const variantId = this.getVariantId()
      if (variantId) {
        return this.props.variants.find(v => v.id === variantId) || null
      }
    }
    return null
  }

  renderToggles = () => {
    const { item } = this.props
    const toggles = []
    if (item.variants) {
      toggles.push(...this.buildVariantToggles())
    } else if (item.mto_options) {
      toggles.push(...this.buildMtoToggles())
    }

    toggles.map(toggle => {
      switch (toggle.title.toLowerCase()) {
        case 'size':
          toggle.values = toggle.values.sort(compareSize)
          break
        case 'color':
          toggle.components = toggle.values.map(colorFn)
          break
        default:
          break
      }
      return toggle
    })

    return (
      <div style={{ marginTop: toggles.length > 0 ? '1rem' : 0 }}>
        {toggles.map(toggle => (
          <Toggle
            key={toggle.title}
            title={toggle.title}
            values={toggle.values}
            value={this.state.options[toggle.title]}
            components={toggle.components}
            onChange={this.toggleHandler(toggle.title)}
          />
        ))}
      </div>
    )
  }

  buildVariantToggles() {
    const { item } = this.props

    // get the variant types from the default
    return Object.keys(item.defaults).map(title => {
      const toggle = {
        title,
        // values come from the variants themselves
        values: [...new Set(item.variants.map(v => v[title]))],
        components: null
      }

      return toggle
    })
  }

  buildMtoToggles() {
    const { item } = this.props
    return Object.keys(item.mto_options).map(title => ({
      title,
      values: item.mto_options[title],
      components: null
    }))
  }

  render() {
    const { item } = this.props
    if (!item) {
      return (
        <div style={{ marginTop: '7rem' }}>
          <Container>
            <Skeleton height="3rem" />
            <hr />
            <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2} style={{ marginTop: '1rem', backgroundColor: 'transparent' }}>
              <Grid item sm={6} xs={12}>
                <Skeleton height="40rem" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Skeleton height="2rem" />
                <Skeleton height="2rem" />
                <Skeleton height="2rem" />
                <Skeleton height="10rem" />
              </Grid>
            </Grid>
          </Container>
        </div>
      )
    }
    const variant = this.getVariant()
    const inStock = isInStock(item, variant)
    const itemOrVariant = variant || item
    const { classes } = this.props
    return (
      <div style={{ marginTop: '7rem', paddingBottom: '1rem' }}>
        <Container>
          <Typography variant="h3" style={{ paddingBottom: '1rem' }}>
            {item.title}
          </Typography>

          <hr />
          <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2} style={{ marginTop: '1rem', backgroundColor: 'transparent' }}>
            <Grid item sm={6} xs={12}>
              <div className={classes.imgWrapper}>
                <img alt="description" className={classes.productImg} src={`/img/products/${item.imgs[0]}`} />
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography variant="h6" style={{ textAlign: 'justify', paddingBottom: 0 }}>
                {inStock ? `$ ${itemOrVariant.price}` : 'OUT OF STOCK'}
              </Typography>
              {this.renderToggles()}
              {/* <Typography variant="h6" className="left">
                Description:
              </Typography> */}
              {/* <Typography
                variant="body1"
                style={{ paddingTop: '1rem', textAlign: 'justify' }}
                dangerouslySetInnerHTML={{
                  __html: itemOrVariant.description.split('\n').join('<br/>')
                }}
              /> */}
              <div className={this.props.classes.markdown}>
                <Markdown options={{ overrides: { p: Paragraph } }}>{itemOrVariant.description}</Markdown>
              </div>

              <div className="space-v flex-wrapper">
                <div className="left flex-0">
                  <Button style={{ padding: '1rem' }} onClick={() => this.props.history.push('/shop')}>
                    <CloseIcon style={{ marginRight: '0.25rem' }} />
                    Back
                  </Button>
                </div>

                <div className="right flex-1">
                  <Fab
                    variant="extended"
                    color="secondary"
                    disabled={!inStock}
                    onClick={() => this.props.addToCart(itemOrVariant.id, this.state.options, item.id)}
                  >
                    <AddShoppingCartIcon style={{ marginRight: '0.25rem' }} /> Add to Cart
                  </Fab>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default connect(
  // @ts-ignore
  (state, props) => ({ orientation: state.site.orientation, item: getProduct(state, props), variants: getProductVariants(state, props) }),
  { addToCart }
)(useStyles(withRouter(Product)))
