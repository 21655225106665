import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store'
import { getAllProducts } from './store/actions'
import { createMuiTheme, responsiveFontSizes, MuiThemeProvider } from '@material-ui/core/styles'
// import red from '@material-ui/core/colors/blueGrey'

let theme = createMuiTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily: ['Source Sans Pro', 'sans'].join(',')
  },
  // palette: {
  //   secondary: {
  //     light: red[200],
  //     main: red[500],
  //     dark: red[800],
  //     contrastText: '#fff'
  //   }
  // },
  overrides: {
    MuiListItem: {
      root: {
        height: '3rem',
        fontSize: 24
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '1rem',
        paddingRight: '1rem'
      },
      head: {
        fontSize: '1.25rem',
        color: 'black',
        fontFamily: 'Black Ops One'
      }
    },
    MuiLink: {
      root: {
        textDecoration: 'none'
      }
    }
  }
})
theme = responsiveFontSizes(theme)

store.dispatch(getAllProducts())

ReactDOM.render(
  <Provider store={store}>
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </BrowserRouter>
    </StripeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
