import React from 'react'
import Hero from '../components/hero'
import SectionHeader from '../components/section-header'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'
import Fade from 'react-reveal/Fade'
// @ts-ignore
import { Parallax } from 'react-parallax'
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Topic from '../components/topic'
import { setCategory } from '../store/actions'
import Fab from '@material-ui/core/Fab'

const useStyles = withStyles({
  logo: {
    height: '30vh'
  }
})

class Home extends React.Component {
  render() {
    return (
      <div>
        <Hero imageSrc={`/img/galaxy-hero.jpg`}>
          <div style={{ height: '40vh' }} />
          <div className="center" style={{ paddingBottom: '5px' }}>
            <Fade bottom delay={250} duration={1500}>
              <img src={`/img/rwc-logo.png`} alt="Rock Warrior Club" className={this.props.classes.logo} />
              <Typography variant="h1" className="stencil no-select-text">
                Rock Warrior Club
              </Typography>
            </Fade>
          </div>
        </Hero>
        <SectionHeader>Do You Have What It Takes...</SectionHeader>
        <Parallax bgImage={`/img/jumping-rock-hero.jpg`} strength={this.props.orientation === 'horizontal' ? 500 : 150}>
          <div style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.57)' }}>
            <Container>
              <Fade bottom distance={'50px'}>
                <Typography variant="h4" className="img-text serif" style={{ flex: 0, paddingTop: '3rem', paddingBottom: '3rem' }}>
                  To live a life filled with faith, <br />
                  healing, and transformation? <br />
                  <br />
                  <big>Are you ready...</big>
                  <br />
                  <big>To let Jesus have his way in your life?</big> <br />
                  <br />
                  To change your character...
                  <br />
                  And live a spiritual Life?
                  <br />
                  <br />
                  Are you ready to take the jump
                  <br /> to see if you can fly?
                </Typography>
              </Fade>
            </Container>
          </div>
        </Parallax>
        <Container style={{ margin: '3rem auto' }}>
          <Typography variant="h4" className="stencil">
            Rock Warrior Club
          </Typography>
          <hr />
          <Typography variant="h5" className="stencil">
            Your premiere source To living a healthy, holy and victorious life In Jesus
          </Typography>
          <Grid container direction="row" justify="center" alignItems="center" spacing={2} style={{ marginTop: '2rem' }}>
            <Grid item md={4} sm={6} xs={12}>
              <Topic imgSrc="/img/topics/health-and-supplements.jpg" link="/shop" beforeRoute={() => this.props.setCategory('Health')}>
                Health &amp; Supplements
              </Topic>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Topic imgSrc="/img/topics/lifestyle.jpg" link="/lifestyle">
                Lifestyle
              </Topic>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Topic imgSrc="/img/topics/training.jpg" link="/training">
                Training
              </Topic>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Topic imgSrc="/img/topics/about.jpg" link="/about">
                About RWC
              </Topic>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Topic imgSrc="/img/topics/apparel.jpg" link="/shop" beforeRoute={() => this.props.setCategory("Men's Clothing")}>
                Apparel
              </Topic>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Topic imgSrc="/img/topics/affiliates.jpg" link="/affiliates">
                Affiliates
              </Topic>
            </Grid>
          </Grid>
        </Container>
        <Container style={{ padding: '0 0 3rem' }}>
          <Typography variant="h4" className="stencil" style={{ padding: '0 0 1rem 0' }}>
            Are You Ready?
          </Typography>
          <a href="https://mailchi.mp/d1600641de65/rwc">
            <Fab size="large" variant="extended" color="secondary" style={{ overflow: 'hidden', height: 'auto' }}>
              <Typography variant="h5" className="stencil" style={{ textTransform: 'none', padding: '0.5rem 1rem' }}>
                Join The Movement. Subscribe.
              </Typography>
            </Fab>
          </a>
        </Container>
      </div>
    )
  }
}

export default connect(
  // @ts-ignore
  state => ({ orientation: state.site.orientation }),
  { setCategory }
)(useStyles(Home))
