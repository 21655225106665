import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Skeleton from '@material-ui/lab/Skeleton'
import { withRouter } from 'react-router-dom'
import { getCartProducts } from '../store/selectors/products'
import { updateItemInCart, removeItemFromCart } from '../store/actions'

const useStyles = withStyles(theme => ({
  root: {
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'row',
    transition: 'background-color 1s',
    height: '5rem'
  },
  appBarContent: {
    height: '5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    userSelect: 'none'
  },
  rootAtTop: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    boxShadow: 'none'
  },
  logo: {
    height: '5rem',
    display: 'inline'
  },
  rightWrapper: {
    flex: 0,
    paddingRight: '1rem',
    paddingLeft: '1rem'
  },
  buttonBase: {
    width: '4rem',
    height: '4rem'
  },
  appIcon: {
    width: '2.2rem',
    height: '2.2rem',
    color: 'white'
  },
  logoWrapper: {
    flex: 0,
    paddingLeft: '0rem',
    paddingRight: '1rem'
  },
  flex: { flex: 1 },
  badge: {
    margin: 0
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  nested: {
    marginLeft: theme.spacing(4)
  },
  textField: {
    width: '10rem',
    textAlign: 'right'
  },
  chip: {
    margin: '0 0.5rem'
  }
}))

const qtyList = [...Array(100).keys()]
qtyList.shift()

const toDollar = num => (num === null ? <Skeleton style={{ margin: 0, padding: 0 }} /> : '$ ' + (Math.round(num * 100) / 100).toFixed(2))
const toChips = opts => (
  <div style={{ marginTop: '0.25rem' }}>
    {Object.entries(opts).map((kv, ix) => (
      <Chip key={ix} label={`${kv[0]} | ${kv[1]}`} size="small" style={{ margin: '0.125rem', textTransform: 'capitalize' }} />
    ))}
  </div>
)

class Cart extends React.Component {
  render() {
    const cartProducts = this.props.cartProducts || []
    const { subtotal_cost, tax_cost, shipping_cost, total_cost } = this.props.orderInfo
    return (
      <div>
        <Table style={{ maxWidth: '100%', marginTop: '1rem', paddingTop: '0rem', borderTop: '1px solid #ccc' }}>
          <TableHead>
            <TableRow>
              <TableCell className="sans small-header">Item</TableCell>
              <TableCell className="sans small-header" align="right">
                Qty
              </TableCell>
              <TableCell className="sans small-header" align="right">
                Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartProducts.map((cartItem, ix) => (
              <TableRow key={ix}>
                <TableCell>
                  <Typography variant="body1">{cartItem.title}</Typography>
                  {cartItem.options && toChips(cartItem.options)}
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">{cartItem.quantity}</Typography>
                </TableCell>
                <TableCell align="right">{toDollar(cartItem.price * cartItem.quantity)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell style={{ borderBottom: 'none' }} className="summary-row">
                SubTotal
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }} className="summary-row"></TableCell>
              <TableCell align="right" style={{ borderBottom: 'none' }} className="summary-row">
                {toDollar(subtotal_cost)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: 'none' }} className="summary-row">
                Tax
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }} className="summary-row"></TableCell>
              <TableCell align="right" style={{ borderBottom: 'none' }} className="summary-row">
                {toDollar(tax_cost)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="summary-row">S &amp; H</TableCell>
              <TableCell className="summary-row"></TableCell>
              <TableCell align="right" className="summary-row">
                {toDollar(shipping_cost)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ borderBottom: 'none' }} className="summary-row">
                <strong>Total</strong>
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }} className="summary-row"></TableCell>
              <TableCell align="right" style={{ borderBottom: 'none' }} className="summary-row">
                <strong>{toDollar(total_cost)}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    )
  }
}

Cart.propTypes = {
  closeCart: PropTypes.func,
  orderInfo: PropTypes.any
}

export default connect(
  state => ({
    cartProducts: getCartProducts(state)
  }),
  { updateItemInCart, removeItemFromCart }
)(useStyles(withRouter(Cart)))
