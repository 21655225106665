import service from './serviceFactory'

export default {
  products: {
    getAll: async () => service().get('/api/inv/items/from/RWC'),
    getByCategory: async category => service().get(`/api/inv/items/from/RWC.${category}`)
  },
  orders: {
    build: async data => service().post(`/api/orders/build`, data),
    submit: async data => service().post(`/api/orders/submit`, data)
  },
  training: {}
}
