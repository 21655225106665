import axios from 'axios'
import swal from 'sweetalert2'

function serviceFactory(options = {}) {
  const headers = options.headers || {}

  const service = axios.create({
    baseURL: (options && options.uri) || process.env.REACT_APP_API_URL,
    timeout: (options && options.timeout) || 10000,
    headers
  })

  service.interceptors.response.use(
    res => {
      return res
    },
    async error => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            await swal.fire({
              titleText: 'Session Expired',
              text: 'Your session has expired. you are being redirected to the login page.',
              type: 'warning'
            })
            return Promise.reject(error)
          case 403:
            await swal.fire({
              titleText: 'Session Expired',
              text: 'Sorry, you are unauthorized to perform that task.',
              type: 'warning'
            })
            return Promise.reject(error)
          case 404:
          case 422:
          case 500:
          case 502:
          default:
            await swal.fire({
              titleText: 'Sorry, and unexpected error occurred. If problem persists, please contact support.',
              text: error.response.data.message,
              type: 'warning'
            })
            return Promise.reject(error)
        }
      }
      return Promise.reject(error)
    }
  )

  return service
}

export default serviceFactory
