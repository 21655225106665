import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import ButtonBase from '@material-ui/core/ButtonBase'
import ShopIcon from '@material-ui/icons/ShoppingCart'
import MenuIcon from '@material-ui/icons/Menu'
import BackArrowIcon from '@material-ui/icons/ArrowBack'
import WhatsHotIcon from '@material-ui/icons/Whatshot'
import InfoIcon from '@material-ui/icons/Info'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike'
import HomeIcon from '@material-ui/icons/Home'
import GroupIcon from '@material-ui/icons/Group'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import Drawer from '@material-ui/core/Drawer'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { getCartProducts } from '../store/selectors/products'
import Checkout from './checkout'
import { setCategory } from '../store/actions'

const useStyles = withStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0,0,0,1)',
    display: 'flex',
    flexDirection: 'row',
    transition: 'background-color 1s',
    height: '5rem',
    boxShadow: 'none'
  },
  appBarContent: {
    height: '5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    userSelect: 'none'
  },
  rootAtTop: {
    background: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0))',
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none'
  },
  logo: {
    height: '5rem',
    display: 'inline'
  },
  rightWrapper: {
    flex: 0,
    paddingRight: '1rem',
    paddingLeft: '1rem'
  },
  buttonBase: {
    width: '4rem',
    height: '4rem'
  },
  appIcon: {
    width: '2.2rem',
    height: '2.2rem',
    color: 'white'
  },
  logoWrapper: {
    flex: 0,
    paddingLeft: '0rem',
    paddingRight: '1rem'
  },
  flex: { flex: 1 },
  badge: {
    margin: 0
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  nested: {
    marginLeft: theme.spacing(4)
  }
}))

const links = [
  { title: 'Home', link: '/', icon: <HomeIcon />, beforeRoute: () => {} },
  { title: 'About', link: '/about', icon: <InfoIcon />, beforeRoute: () => {} },
  {
    title: 'Shop',
    link: '/shop',
    icon: <LocalOfferIcon />,
    beforeRoute: props => {
      props.setCategory('All')
    }
  },
  { title: 'Training', link: '/training', icon: <DirectionsBikeIcon />, beforeRoute: () => {} },
  { title: 'Lifestyle', link: '/lifestyle', icon: <WhatsHotIcon />, beforeRoute: () => {} },
  { title: 'Affiliates', link: '/affiliates', icon: <GroupIcon />, beforeRoute: () => {} }
]

class Nav extends React.Component {
  state = {
    transparent: true,
    menuOpen: false,
    cartOpen: false
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleScroll()
    }
  }
  hasTransparentBar = () => {
    return ['/', '/training', '/lifestyle', '/affiliates', '/about'].includes(this.props.location.pathname)
  }
  setTransparent = val => {
    if (this.state.transparent !== val) {
      this.setState({ transparent: val })
    }
  }
  handleScroll = () => {
    const scrollY = window.scrollY
    if (this.hasTransparentBar()) {
      if (scrollY <= 80) {
        this.setTransparent(true)
      } else {
        this.setTransparent(false)
      }
    } else {
      this.setTransparent(false)
    }
  }
  setField = (fieldname, value) => () => {
    this.setState({ [fieldname]: value })
  }
  linkTo = (value, beforeRoute) => () => {
    beforeRoute(this.props)
    this.props.history.push(value)
    this.setState({ menuOpen: false })
  }
  render() {
    const { classes } = this.props
    return (
      <>
        <AppBar position="fixed" className={classes.root + ' ' + (this.state.transparent ? classes.rootAtTop : '')}>
          <div className={classes.menuWrapper}>
            <div className={classes.appBarContent}>
              <ButtonBase className={classes.buttonBase} onClick={this.setField('menuOpen', true)}>
                <MenuIcon className={classes.appIcon} />
              </ButtonBase>
            </div>
          </div>
          <div className={classes.logoWrapper}>
            <div className={classes.appBarContent}>
              <Link to="/">
                <Typography variant="h4" style={{ whiteSpace: 'nowrap', textShadow: '2px 2px 4px rgba(0,0,0,0.4)' }} className="stencil">
                  RWC
                </Typography>
              </Link>
            </div>
          </div>
          <div className={classes.flex} />
          <div className={classes.rightWrapper}>
            <div className={classes.appBarContent}>
              <ButtonBase className={classes.buttonBase} onClick={this.setField('cartOpen', true)}>
                <Badge className={classes.badge} badgeContent={this.props.cartItems.length} color="primary">
                  <ShopIcon className={classes.appIcon} />
                </Badge>
              </ButtonBase>
            </div>
          </div>
        </AppBar>
        <Drawer open={this.state.menuOpen} onClose={this.setField('menuOpen', false)}>
          <Paper style={{ width: 400, maxWidth: '75vw', height: '100vh' }}>
            <div style={{ display: 'flex', backgroundColor: 'rgba(0,0,0,0.2)' }}>
              <div className={classes.appBarContent}>
                <ButtonBase className={classes.buttonBase} onClick={this.setField('menuOpen', false)}>
                  <BackArrowIcon className={classes.appIcon} style={{ color: '#555' }} />
                </ButtonBase>
              </div>
              <div className={classes.flex} style={{ paddingRight: '5rem' }}>
                <div className={classes.appBarContent + ' center'}>
                  <Typography variant="h4" className="stencil text">
                    RWC
                  </Typography>
                </div>
              </div>
            </div>
            <List>
              {links.map(l => (
                <ListItem key={l.title} button onClick={this.linkTo(l.link, l.beforeRoute)} className="stencil">
                  <ListItemIcon>{l.icon}</ListItemIcon>
                  {l.title}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Drawer>
        <Drawer anchor="right" open={this.state.cartOpen} onClose={this.setField('cartOpen', false)}>
          <Paper style={{ width: 600, maxWidth: '100vw', height: '100vh', overflowY: 'auto' }}>
            <div style={{ display: 'flex', backgroundColor: 'rgba(0,0,0,0.2)' }}>
              <div className={classes.appBarContent}>
                <ButtonBase className={classes.buttonBase} onClick={this.setField('cartOpen', false)}>
                  <BackArrowIcon className={classes.appIcon} style={{ color: '#555' }} />
                </ButtonBase>
              </div>
              <div className={classes.flex} style={{ paddingRight: '5rem' }}>
                <div className={classes.appBarContent + ' center'}>
                  <Typography variant="h4" className="stencil text">
                    Cart
                  </Typography>
                </div>
              </div>
            </div>
            <Checkout />
          </Paper>
        </Drawer>
      </>
    )
  }
}

export default connect(
  state => ({ cartItems: getCartProducts(state) }),
  { setCategory }
)(useStyles(withRouter(Nav)))
