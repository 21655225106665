import React from 'react'
import PropTypes from 'prop-types'
import { injectStripe, CardElement } from 'react-stripe-elements'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import BackIcon from '@material-ui/icons/ArrowBack'
import { CircularProgress, Typography } from '@material-ui/core'
import api from '../api'
import Swal from 'sweetalert2'

/**
 * There are three parts to an order:
 *  - orderInfo: details of the logistics: shipping, billing, email, etc.
 *  - itemInfo: details of the items
 *  - payInfo: details on the payment
 * orderInfo and itemInfo are passed in to make this component modular.
 * payInfo is generated in this component's state.
 */

class Payment extends React.Component {
  state = {
    cardComplete: false,
    submitAttempted: false,
    isPaymentProcessing: false,
    orderNumber: null
  }
  constructor(props) {
    super(props)
    this.card = React.createRef()
  }

  handleSubmit = async () => {
    // todo
    const { orderInfo } = this.props
    let token = {}

    if (!orderInfo) {
      return
    } else if (!this.state.cardComplete) {
      this.setState({ submitAttempted: true })
      this.card.current._element.focus()
      return false
    } else if (!orderInfo.total_cost) {
      return
    } else {
      // submit order

      this.setState({ isPaymentProcessing: true })

      try {
        const response = await this.props.stripe.createToken({
          type: 'card',
          name: `${orderInfo.first_name} ${orderInfo.last_name}`,
          address_line1: orderInfo.billing_address1,
          aaddress_line2: orderInfo.billing_address2,
          address_city: orderInfo.billing_city,
          address_state: orderInfo.billing_state,
          address_zip: orderInfo.billing_zip,
          address_country: 'USA'
        })

        token = response.token
      } catch (err) {
        await Swal.fire('Could not process card', 'We were unable to process the card you entered. Please double-check your info and try again.', 'warning')
        this.setState({ isPaymentProcessing: false })
        return
      }

      const payInfo = {
        cc_token: token.id,
        cc_last4: token.card.last4,
        amount: orderInfo.total_cost
      }
      const items = this.props.itemInfo.map(item => ({
        id: item.id,
        qty: item.quantity,
        options: item.options
      }))

      try {
        const response = await api.orders.submit({
          orderInfo,
          payInfo,
          items
        })

        this.setState({ isPaymentProcessing: false })
        this.props.onSuccess(response.data.order_number)
      } catch (err) {
        this.setState({ isPaymentProcessing: false })
      }
    }
  }

  render() {
    return (
      <div style={{ borderBottom: '1px soid #ccc' }} className={this.state.submitAttempted ? 'submit-attempted' : ''}>
        <label style={{ color: '#888', fontSize: '0.75rem', padding: '0.5rem 0.25rem', display: 'block' }}>Credit or Debit Card</label>
        <CardElement ref={this.card} onChange={change => this.setState({ cardComplete: change.complete })} />
        <div className="flex-wrapper" style={{ alignItems: 'center', paddingTop: '1rem' }}>
          <div className="flex-1">
            <Button onClick={this.props.onBack}>
              <BackIcon className="space-r" />
              Back
            </Button>
          </div>
          <div style={{ padding: '1rem 0', textAlign: 'right' }}>
            <Fab variant="extended" color="secondary" onClick={this.handleSubmit}>
              Complete Purchase
              {this.state.isPaymentProcessing ? <CircularProgress className="space-l" style={{ color: 'white' }} /> : <CheckIcon className="space-l" />}
            </Fab>
            {this.state.isPaymentProcessing && (
              <Typography variant="body2" style={{ marginTop: '1rem' }}>
                Do not leave this sidebar or hit refresh while payment is processing
              </Typography>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Payment.propTypes = {
  orderInfo: PropTypes.object, // all details to submit
  itemInfo: PropTypes.array, // could use connect... but this way it's more modular
  onSuccess: PropTypes.func, // onSuccess(orderNumber)
  onBack: PropTypes.func
}

export default injectStripe(Payment)
