import React from 'react'
import { connect } from 'react-redux'
import { Container, Grid, Typography, ButtonBase } from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/styles'
// @ts-ignore
import { Parallax } from 'react-parallax'

const useStyles = withStyles({
  title: {
    border: 0,
    padding: 0,
    borderTop: '1px solid #ccc',
    '& legend': {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  }
})

// TEEHHtQWGJo&list=PLoz9kRrcjU_oTiAMthXYKSSktjaH-p9ox
// Latest Video from channel: src="https://www.youtube.com/embed?listType=user_uploads&list=doubleeaglesministry&modestbranding=1&rel=0"
// Latest Video from playlist: src="https://www.youtube.com/embed/videoseries?list=TEEHHtQWGJo&list=PLoz9kRrcjU_oTiAMthXYKSSktjaH-p9ox&modestbranding=1&rel=0&max-results=1"
class Affiliates extends React.Component {
  isWidth = (...sizes) => sizes.includes(this.props.width)

  render() {
    const { classes } = this.props
    return (
      <div style={{ minHeight: 'calc(100vh - 15.75rem)' }}>
        <Parallax bgImage="/img/pebbles-diamond.jpg" strength={500}>
          <div style={{ backgroundColor: 'rgba(0,0,0,0.57)' }}>
            <Container style={{ paddingTop: '7rem', paddingBottom: '2rem', color: 'white', textShadow: '2px 2px 4px rgba(0,0,0,0.6)' }}>
              <fieldset className={classes.title}>
                <legend className="center">
                  <Typography variant="h2" className="stencil" style={{ marginBottom: '0' }}>
                    Affiliates
                  </Typography>
                </legend>
              </fieldset>
              <Typography variant="h5"></Typography>
              <div style={{ height: '4rem' }} />
              <Typography variant={this.isWidth('xs') ? 'h5' : 'h4'} className="serif space-v" style={{ lineHeight: '1.6' }}>
                These are some companies
                <br />
                that have products and supplements
                <br />
                that I have used
                <br />
                <img alt="Andrew LaCombe" src="/img/andrew-signature.png" style={{ maxWidth: '100%' }} />
              </Typography>
              <div style={{ height: '4rem' }} />
            </Container>
          </div>
        </Parallax>

        <Container style={{ borderBottom: '1px solid #ccc', paddingTop: '5rem' }}>
          <Grid container direction="row" alignContent="stretch" alignItems="stretch" spacing={2}>
            <Grid item xs={12} md={6}>
              <ButtonBase>
                <a href="http://www.jcrowsllc.com?aff=184">
                  <img style={{ height: 126 }} src={'/img/affiliates/j-crows.jpg'} alt="J. Crow's" />
                </a>
              </ButtonBase>
            </Grid>
            <Grid item xs={12} md={6}>
              <a
                aria-label="Health Ranger Store Link"
                href="https://www.healthrangerstore.com/?rfsn=3325659.74c961&utm_source=HR_Affiliate&utm_campaign=14708&utm_affiliate=3325659"
              >
                <img style={{ height: 126 }} src={'/img/affiliates/health-ranger.png'} alt="health ranger" />
              </a>
            </Grid>
            <Grid item xs={12} md={6}>
              <a href="http://www.GlobalHealingCenter.com/?a_aid=578444fbd22f2">
                <img style={{ height: 126 }} src={'/img/affiliates/global-healing-center.jpg'} alt="bulletproof" />
              </a>
            </Grid>
            <Grid item xs={12} md={6}>
              <a href="https://tracking.activationproducts.com/JPRGP6GZ/25BDZST/?source_id=RWC">
                <img style={{ height: 126 }} src={'/img/affiliates/activation-products.jpg'} alt="activation products" />
              </a>
            </Grid>
          </Grid>
          <Container maxWidth="md" style={{ padding: '3rem 0' }}>
            <Typography variant="body2">
              <small>
                <em>
                  Disclosure of Material Connection: The links in the post above are "affiliate links." This means if you click on the link and purchase the
                  item, I will receive an affiliate commission. Regardless, I only recommend products or services I use personally and believe will add value to
                  my readers. I am disclosing this in accordance with the Federal Trade Commission’s 16 CFR, Part 255: "Guides Concerning the Use of
                  Endorsements and Testimonials in Advertising."
                </em>
              </small>
            </Typography>
          </Container>
        </Container>
      </div>
    )
  }
}
export default connect(
  // @ts-ignore
  // state => ({ width: state.site.width }),
  state => ({}),
  {}
)(withWidth()(useStyles(Affiliates)))
