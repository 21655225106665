import types from '../types'

const products = (state = [], action) => {
  switch (action.type) {
    case types.SET_ALL_PRODUCTS:
      // completely rewrite all things. Thinking eventually, to set_all_clothing_products, etc. as we grow.
      return action.payload
    default:
      return state
  }
}

export default products
